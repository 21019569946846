<template>
  <EditBillSettlementComp />
</template>

<script>
export default {
  name: "EditSettlement",
  components: {
    EditBillSettlementComp: () => import("../../../src/components/bill_settlements/EditBillSettlementComp.vue"),
  },
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Settlements", path:"/bills/settlements", isActive: false}, {text:"Edit", path:"", isActive: true}] 
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>